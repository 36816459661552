$green: #01B17B;
$dark-green: #009F6E;
$mid-gray: #DEDEDE;
$light-gray: #DADDE0;
$lighter-gray: #E2E2E2;
$lightest-gray: #EEE;
$gray: #8997AD;
$blue: #319EDD;
$dark-blue: #147eb9;
$light-blue: #EAF6FC;
$dark: #434B58;
$red: #ce0c25;
$error-red: #E02D52;
$body-text_color: #767676;
$link-color: #006aff;
$link-hover-color: #479ffe;
$common-border: #58798F80;
$default-text-color: #000000;

// http://getbootstrap.com/css/#grid
$screen-xxs-max: 320px; // older mobile phones
$screen-xs-max: 767px; // mobile phone
$screen-sm-max: 991px; // tablet
$screen-md-max: 1199px; // laptop

$font-xs: 11px;
