@import '../../styles/variables';

.productName {
  padding: 6px 0;
  display: flex;
  gap: 4px;
  align-items: center;
  
  .logo {
    display: inline-block;
    margin-left: 15px;
    padding-right: 6px;
    width: 24px;
    float: left;
  }
  .description {
    font-family: lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-right: 15px;
    overflow: hidden;
  }
}
