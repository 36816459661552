@import './src/styles/_variables';

.connectedAccount {
  display: inline-flex;
  gap: 6px;
  padding-top: 4px;
}

.passkeyModalHeader {
  :last-child {
    margin-top: 8px;
  }
}

.passkeyModalButton{
    margin-top: 20px;
}

.iconGroup {
  display: inline-flex;
  gap: 16px;
  padding-top: 4px;
  margin-bottom: 16px;
}

.signinSecurityContainer {
  margin: 0 auto;
}

.mfaSection {
  margin: 0;
}

.pairedDevice {
  margin: 8px 0;
}

.socialCollection {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.enterpriseSignIn :first-child {
  margin-bottom: 5px;
}

.enterpriseSignIn :last-child {
  margin-top: 3px;
}

.emailVerificaionContainer > *:not(:first-child) {
  margin-top: 16px;
}

.enhanceSecurityConatiner > *{
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.enhanceSecurityToggler {
  display: flex;
  justify-content: space-between;
}

.ctaSection {
  display: flex;
  flex-direction: row-reverse;
  min-height: 72px;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;
  position: absolute;
}

.ctaSectionConatiner {
  display: flex;
  justify-content: end;
}
.modal {
  position: relative;
  top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 15px;
  text-align: center;
  max-width: 540px;
  background-color: #FFFFFF;
  box-shadow: 4px 4px 2px #888888;
}

@media (max-width: 550px) {
  .modal {
    top: 0px;
  }
}

.siColumn {
  padding: 0 20px;
}

.connectedAccountsGroup {
  margin-bottom: 16px;
}

.lastLineConclusion {
  margin-top: 16px;
}

@media (max-width: 768px) {
  .siColumn {
    width: 320px;
    float: none;
    margin: 0 auto;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(circle, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  /* For Safari 5.1 to 6.0 */
  background: -o-radial-gradient(circle, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  /* For Opera 11.1 to 12.0 */
  background: -moz-radial-gradient(circle, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  /* For Firefox 3.6 to 15 */
  background: radial-gradient(circle, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  /* Standard syntax */
}

.sectionInfo {
  font-size: 14px;
  line-height: 1.43;
  padding: 10px 0 4px;
  letter-spacing: 0.2px;
  color: #444444;
}

.staticFieldLabel,
.deviceLocation {
  font-size: 13px;
  line-height: 0.77;
  padding: 14px 0 4px;
  letter-spacing: 0.1px;
  color: $body-text_color;
}

.deviceLocation {
  padding: 0;
  color: #444444;
}

.staticFieldValue,
.devicePlatform {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #444444;
  overflow: auto;
  padding-top: 6px;
  padding-bottom: 7px;
}

.devicePlatform {
  font-weight: 700;
}

.editLink,
.editLinkViewOnly {
  font-size: 13px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.1px;
  color: #479FFE;
  white-space: nowrap;
  cursor: pointer;
  padding-top: 28px;
}

.editLinkViewOnly {
  cursor: default;
}

.dropBoxLine1 {
  font-size: 16px;
  color: #666666;
}

.dropBoxLine2,
.modalResetPhotoLink {
  font-size: 12px;
  padding-top: 10px;
  color: #666666;
}

.dropBoxLine3 {
  font-size: 10px;
  padding-top: 10px;
  color: #888888
}

.modalResetPhotoLink {
  padding: 0 0 10px;
}

.socialLogo {
  height: 21px;
  width: 21px;
  padding: 2px;
}

.socialLabel {
  font-size: 16px;
  letter-spacing: 0.2px;
  padding-top: 10px;
  color: #434b58;
  white-space: nowrap;
}

.otherWaysSignInText {
  margin-bottom: 4px;
}

.signInMethodsubHeading {
  margin-bottom: 16px;
}

.socialConnection {
  line-height: 19px;
  text-overflow: ellipsis;
  padding-bottom: 4px;
  overflow: hidden;
}

.userInstruction,
.passwordEditUserInstruction {
  font-size: 16px;
  letter-spacing: 0.2px;
  padding-bottom: 18px;
}

.passwordEditUserInstruction {
  padding-bottom: 10px;
}

.passwordEditOrSeparator {
  font-size: 14px;
  color: #9B9B9B;
  text-align: center;
  padding: 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.orDash {
  display: inline-block;
  margin: 0 10px;
  height: 5px;
  border-top: 2px solid #9B9B9B;
  width: 24px;
}

.actionLink {
  font-size: 13px;
  letter-spacing: 0.1px;
  padding-bottom: 4px;
}

.enrolledImage {
  margin: 14px 0;
  height: 20px;
  width: 20px;
}

.linkLabel,
.devicesModalLink {
  font-size: 16px;
  letter-spacing: 0.2px;
  padding-top: 10px;
  color: #434b58;
  padding-left: 0px;
}

.devicesModalLink {
  padding-top: 14px;
}

.devicesMap {
  width: 100%;
  height: 200px;
}

.deviceLocations {
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
}

.deviceSpec {
  line-height: 1.7;
}

.deviceMessage {
  margin-top: 12px;
}

.disclaimer {
  margin-top: 19px;
}

.reportDeviceMessage {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  button {
    max-width: 360px;
  }
}

.allSetImage {
  height: 40px;
  width: 40px;
  margin-top: 6px;
  margin-bottom: 25px;
}

.mailrobot {
  padding-top: 16px;
  padding-bottom: 10px;
}

.enhancedSecurity {

  .sectionInfo,
  .staticFieldLabel {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sectionInfo {
    padding-bottom: 8px;
  }

  .staticFieldLabel {
    line-height: normal;
    padding-top: 4px;

    .borderTop {
      padding-top: 8px;
      border-top: 1px solid #DCDCDC;
    }
  }

  .mfaStatus {
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 15px 0;

    div {
      padding: 10px 15px;
    }

    .status-indicator {
      background: #F9F9F9;
      border-bottom: 1px solid #DCDCDC;
      box-sizing: border-box;
      font-size: 16px;
      color: #444444;

      img {
        display: inline-block;
        padding-right: 12px;
        margin-top: -3px;
      }
    }
  }
}