@import './variables';

html, body {
  margin: 0;
  padding: 0;
  height: 100%; 
  overflow-x: hidden;
}

.mainContainer {
  display: flex;
  column-gap: 20px;
}

.singleColumn {
  flex: 1 1 0;
  min-width: 0;
  max-width: 33%;
  box-sizing: border-box;
}

.emailText {
  white-space: nowrap;
  font-weight: bold;
}

.loader-background {
  fill: none;
  stroke-width: 2px;
  stroke: #f7f7f7;
}

.loader-container {
    width: 32px;
    display: flex;
    margin: auto;
    height: 100vh;
    align-items: center;
}

.loader {
  fill: none;
  stroke: #2c72de;
  stroke-width: 2px;
  stroke-dashoffset: 10;
  stroke-dasharray: 20;
  transform-origin: 50% 50% 0px;
  transform: rotate(-90deg);
  -webkit-animation:spin-infinite 0.7s linear infinite;
  -moz-animation: spin-infinite 0.7s linear infinite;
  -o-animation: spin-infinite 0.7s linear infinite;
  -ms-transition: spin-infinite 0.7s linear infinite;
  transition: spin-infinite 0.7s linear infinite;
  animation: spin-infinite 0.7s linear infinite;
}

@media (max-width: $screen-xs-max) {
  .singleColumn {
    width: 100%;
    max-width: 100%;
  }
  .pageHeader {
    margin: 8px auto 24px auto;
  }
  .mainContainer {
    display: contents;
   }
  .text-center-xs {
    text-align: center;
  }
  .app-content {
    padding-bottom: 155px;
  }
}

.pageHeader {
  margin-bottom: 16px;
}

a {
  text-decoration: auto !important;
  color: #2C72DE !important;
}


.centerAnyContent {
  height: calc(100vh - 88px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-state {
  background-color: rgba(44, 114, 222, 0.1);
}

.navbar-brand {
  margin-right: 15px;
}

// general
.no-margin {
  margin: 0;
}

hr {
  border-top-color: $mid-gray;
}

.regularText {
  font-family: lato-regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.vertical-divider {
  border-right: 1px solid $light-gray;
}

.popover-content {
  padding: 0;
}

.alert-danger,
.alert-warning,
.alert-info {
  height: auto;
  background-color: #F8E8A2;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0 !important;
  color: #000000;

  i {
    color: #000000 !important;
  }

  .close {
    font-family: lato-regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 24px;

    &:hover {
      color: #000000;
    }
  }
}

.alert-info,
.alert-danger {
  background-color: #006aff;
  color: #FFFFFF;

  i {
    color: #FFFFFF !important;
  }

  a {
    color: #FFFFFF !important;
    text-decoration: underline !important;
  }

  .close {
    &:hover {
      color: #FFFFFF;
    }
  }
}

.alert-danger {
  background-color: #e02d52;
}

@media (max-width: $screen-sm-max) {
  .mobile-font-md {
    font-size: 16px;
  }
}

ul.no-bullets {
  list-style: none;
}

.green-text {
  color: #01B17B;
}

.superscript-currency {
  sub {
    bottom: 0;
    font-size: .6em;
  }
}

@media (max-width: 900px) {
  .mobile-font-md {
    font-size: 16px;
  }
}

.vcenter-container {
  display: table;
  width: 100%;
  height: 100%;
}

.vcenter-container-ellipsis {
  @extend .vcenter-container;
  table-layout: fixed;
}

.vcenter-content {
  display: table-cell;
  vertical-align: middle;
}

hr {
  margin: 0;
}



// ToGo footer - adjust padding bottom on elements
.togo-footer ul li>a,
.footer ul li>span {
  padding-bottom: 10px;
}


// Adjust the app content padding bottom since our footer is larger than our default togo-footer due to the OV legal text
.app-content {
  padding-bottom: 95px;
}


// TEMPORARY OVERRIDES - INCORPORATE INTO BASE LIBRARY - IF NEEDED

// ToGo anchor link color change
a {
  color: #479ffe;
}

// Nav header left-right padding increase
.container-fluid {
  padding: 0 20px;
}

.shadow-top {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 999;
}

// Help icon next to field labels - move down 2px
.help-icon-move-down {
  position: relative;
  top: 2px;
}


//Max width of content not to exceed 1200 pixels

.navbar {
  margin-bottom: 0;
}

@media (min-width: $screen-md-max) {
  .app-content {
    width: $screen-md-max;
    margin: 0 auto;
  }
}


//Nav bar overrides

// NavBar "gray band" zone

.no-margin {
  margin: 0 !important;
}

// Avatar border
.img-circle {
  border: 3px solid #FFFFFF;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.20);
  width: 75px;
  height: 75px;
  position: relative;
  background-color: #FFFFFF;
}

// Avatar - position half way up in the navigation
.picture-and-link {
  margin-top: -40px;
}

// Component badge class
.component-badge {
  padding-top: 3px;
  color: #61d4a3;
  background-color: #ffffff;
  font-size: 44px;
}

.navbar-lite {
  .dropdown-toggle:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
  }

  .navbar-brand-lite {
    background-size: auto 38px;
    background-image: url(../static/images/svg/GoTo_logo.svg);
    margin-top: 12px;
    margin-bottom: 10px;
    padding-top: 2px;
    height: 38px;

    span {
      padding-left: 62px;
      color: #000000;
      font-size: 21px;
      line-height: 23px;
      text-transform: none;
      font-family: lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
}

// Container overrides

.app-container {
  min-height: 100%;
}

.app-container-inner {
  min-height: 100%;
  margin-bottom: -50px
}

footer,
.footerSpacer {
  height: 50px;
}

@media (max-width: $screen-xs-max) {
  .app-content {
    padding-bottom: 175px;
  }

  .app-container-inner {
    margin-bottom: -58px
  }

  footer,
  .footerSpacer {
    height: 58px;
  }

  .navbar-lite {
    .navbar-brand-lite {
      background-position: 0 0 !important;
    }
  }
}

@media (min-width: 480px) {
  .navbar-lite {
    .navbar-brand-lite {
      padding-left: 32px;
    }
  }
}

// ToGo footer overrides

.togo-footer {
  background-color: #071b31;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
}

// ToGo footer copyright text color
.togo-footer ul li>a,
.togo-footer ul li>span {
  color: #6f7e91;
}

// ToGo footer GoTo (link?) font size and color
.togo-footer .footer-GoTo-link {
  font-size: 13px;
  color: #ffffff;
  font-family: lato-light;
}


// Form, and form field formatting styles
form {
  margin-top: 20px;
}

// Form -align left - temporary -  to make field labels align left -- JSN
form {
  text-align: left;
}

.form-control {
  height: 45px;
  border-color: #D8D8D8;
  color: #444;
}

.form-control:focus {
  border: 2px solid #469FFF;
}

.form-group,
.input-group {
  margin-bottom: 25px;

  &.shallow {
    margin-bottom: 18px;
  }

  .static-field {
    padding-top: 12px;
    padding-bottom: 11px;
    color: #767676;
    cursor: default;
    overflow: hidden;
  }
}

.view-only-text {
  color: #767676;
  cursor: default;
}

/* Help Icon left padding */
.togo-icon-question-circle-leftpadding {
  padding-left: 6px;
  cursor: pointer;
}

/*Floating label*/
.has-float-label {
  position: relative;
}

.has-float-label label {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 11px;
  color: #89898A;
  opacity: 1;
  top: -.5em;
  left: 12px;
  z-index: 3;
  line-height: 1;
  padding: 0 4px;
}

.has-float-label label::after {
  content: " ";
  display: block;
  position: absolute;
  background: white;
  height: 2px;
  top: 50%;
  left: -.2em;
  right: -.2em;
  z-index: -1;
}

/*Floating label for dropdown*/
.has-float-label {
  position: relative;
}

.dplabel {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 11px;
  color: #89898A;
  opacity: 1;
  top: -.5em;
  left: 12px;
  z-index: 3;
  line-height: 1;
  padding: 0 4px;
}

.dplabel::after {
  content: " ";
  display: block;
  position: absolute;
  background: white;
  height: 2px;
  top: 50%;
  left: -.2em;
  right: -.2em;
  z-index: -1;
}

/*floating labels end*/

DIV .has-error {
  label {
    color: $error-red;
  }

  .help-block {
    font-size: 11px;
    text-align: left;
    background-color: #ffffff;
    color: $error-red;
    padding-bottom: 0;
  }

  .form-control {
    border: 1px solid #f53d5a;
  }

  .input-group-addon {
    color: #fff;
    background-color: $error-red;
    border-color: $error-red;
  }
}

DIV .infoText-block,
DIV .passwordInfoText-block {
  font-size: 12px;
  text-align: left;
  background-color: #ffffff;
  color: #b5b4b5;
  padding-top: 5px;
  padding-bottom: 0;
}

DIV .passwordInfoText-block {
  margin-bottom: 0px;
  font-size: 10px;
  padding-left: 12px;
}

DIV .passwordRequirementMet {
  color: green;
  font-weight: bold;
}

/*.form-control:focus + label {*/
/*color: red;*/
/*}*/

.helper-text {
  color: #B5B4B5;
  font-size: 12px;
  margin: -10px 0 15px 0;
}

.dropdown {
  margin-bottom: 25px;
}

.dropdown button {
  width: 100%;
  height: 45px;
}

/* Button styles and overrides */
.buttonRow {
  margin-top: 40px;
  margin-bottom: 20px;
}



.btn {
  width: 100%;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font-family: lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px !important;
}

.btnPaddingLeft,
.btnPaddingRight {
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 768px) {
  .btnPaddingLeft {
    padding-left: 15px;
    padding-right: 5px;
  }

  .btnPaddingRight {
    padding-left: 5px;
    padding-right: 15px;
  }
}

.btn-default {
  background-color: #006aff !important;
  border-color: #006aff !important;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 20px;
}

.btn-default:not(:disabled):hover {
  background-color: #004fbd !important;
  border-color: #004fbd !important;
}

.btn-default:not(:disabled):active {
  background-color: #004fbd !important;
  border-color: #004fbd !important;
  color: #bfdaff;
}

.btn-default[disabled] {
  color: #dee4ec;
  border-color: #dee4ec;
}

.btn-tertiary {
  border-width: 1px;
  border-style: solid;
  border-color: #b3bfcf;
  background-color: #FFFFFF;
  color: #006aff;
}

.btn-tertiary:not(:disabled):hover {
  background-color: #f7f8fa !important;
  border-color: #828e9e !important;
  color: #006aff !important;
}

.btn-tertiary:not(:disabled):active {
  background-color: #d9e9ff !important;
  border-color: rgb(64, 144, 255) !important;
  color: #004fbd;
}

.btn-default[disabled] {
  color: #ced8e3;
  border-color: rgb(206, 216, 227);
}

.dialogButtonRow {
  text-align: right;

  & .btn-default,
  & .btn-tertiary {
    width: auto !important;
    height: auto !important;
    font-size: 12px !important;
    line-height: 18px !important;
    padding: 6px 24px !important;
    display: inline-block !important;
    margin-bottom: 0;
    position: relative;
  }

  .btn-tertiary {
    border-color: #FFFFFF !important;
    margin-right: 6px;
  }
}

.togo-icon-question-circle {
  color: #479FFE
}

.form-control.select {
  padding: 2px 0;
  text-align: left;

  .react-select__control {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .react-select__single-value {
    padding-right: 4px;
  }

  .react-select__menu {
    margin-top: 1px;
  }

  .react-select__dropdown-indicator {
    cursor: pointer;
    padding-top: 14px;
    width: 30px;
  }
}

.Select-arrow {
  border-color: transparent;
  border-top-color: #479FFE !important;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}

.is-focused:not(.is-open)>.Select-control {
  border-color: transparent !important;
  box-shadow: none !important;
}

.is-open>.Select-control .Select-arrow {
  border-color: transparent;
  border-bottom-color: #479FFE !important;
}

.Select-control:hover {
  box-shadow: none !important;
}

.coloredTextForStrength {
  color: #1B8756;
  font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.learnMoreLink {
  color: $default-text-color !important;
  text-decoration: underline !important;
  text-underline-offset: 2px;
}

.togo-icon-down-arrow {
  color: #479FFE;
}

/* Select Timezone control overrides */
.timezone-picker-list-item {
  text-align: left;
}


/* Select control overrides */
.Select-control {
  padding-left: 0;
  border: none !important;
}

.Select-value {
  padding-left: 0 !important;
  text-align: left;
  border: none;
}

.Select-menu-outer {
  z-index: 1000 !important;
  text-align: left;
  left: 0;
}

/* Help Tooltip overrides */

.helpToolTip {
  max-width: 300px !important;
  border: 1px solid rgb(234, 236, 236) !important;
  text-align: left !important;
  box-sizing: border-box;
  box-shadow: 3px 3px 4px 0 rgba(57, 64, 77, .32);
  border-radius: 2px;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-family: lato-regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: rgb(255, 255, 255) !important;
  opacity: 1.0 !important;
}

.dropBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
  padding: 10px 16px 20px;
  border: 2px dashed rgba(137, 151, 173, .3);
  border-radius: 5px;
  -webkit-transition: border 0.2s;
}

.togo-icon-image {
  font-size: 120px;
  color: rgba(137, 151, 173, .3);
  margin: 0px 0 0 0px;
  letter-spacing: 2px;
  -webkit-transition: color 0.2s;
}

.dropBox.hover {
  border: 2px solid #2684ec;
}

.dropBox.hover DIV,
.dropBox.hover I {
  color: #2684ec;
}

.sectionTitle,
.modalTitle {
  font-size: 18px !important;
  font-family: lato-bold;
  padding-top: 36px;
  letter-spacing: .5px;
  color: #000000;
}

.heading {
  font-size: 16px !important;
  font-family: lato-bold;
  //padding-top: 10px;
  letter-spacing: .5px;
  color: #4a4a4a;
}

.sectionTitle {
  padding-top: 30px;
  color: #4a4a4a;
}

// Begin: React component CSS class overrides
//   The class naming and letter casing of React component classes do not match LMI's standards,
//   but we must match the class names from the respective components for overrides.

// React tooltip
.__react_component_tooltip.type-light.border.place-bottom::before {
  border-bottom: 8px solid #f3f3f3 !important
}

.__react_component_tooltip.type-light.border.place-top::before {
  border-top: 8px solid #f3f3f3 !important
}

// React Modal
.ReactModal__Content--after-open {
  max-height: 100%;
  overflow: scroll;
}

.modalContent .contentDivPadding {
  text-align: left;
  padding-top: 16px;
}

.ReactModal__Body--open {
  overflow: hidden;
}

//  - React Modal CX request: remove the blue border (focus) property from the modal div element itself.
.ReactModal__Content--after-open:focus {
  outline: none !important;
}

// End: React component CSS class overrides