.inlineFieldLayout {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.infoTextOverflow {
  white-space: nowrap;
  overflow: visible;
}

.selectInputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.selectDomain {
  display: flex;
  min-width: 50%;
  position: relative;
  top: 11px;
}
