@import './mixins';

// @TODO (BPOR-98) verify that these transitions don't break IE 9 functionality, they don't have to properly transition since it's not supported by IE 9
// Animation for when a page is loaded or  when we load a new route
.page-appear, .page-enter {
  @include opacity-visible-start-animation();
}

.page-appear.page-appear-active, .page-enter.page-enter-active {
  @include opacity-visible-end-animation(all .25s linear);
}

// Subscriptions overview - details view animation
.details-view-enter {
  @include slide-in-opacity-start-animation();
}

.details-view-enter.details-view-enter-active {
  @include slide-in-opacity-end-animation(200px, all .25s linear);
}

.details-view-leave {
  @include slide-out-opacity-start-animation(200px);
}

.details-view-leave.details-view-leave-active {
  @include slide-out-opacity-end-animation(all .15s ease-out);
}

// Subscriptions overview - details view short animation
.details-view-short-enter {
  @include opacity-visible-start-animation();
}
.details-view-short-enter.details-view-short-enter-active {
  @include opacity-visible-end-animation( all .25s ease-in);
}
.details-view-short-leave {
  @include opacity-hidden-start-animation();
}
.details-view-short-leave.details-view-short-leave-active {
  @include opacity-hidden-end-animation(all .15s ease-in);
}

// Subscription config - selected row animation
.sub-config-selected-row-enter {
  @include slide-in-opacity-start-animation();
}

.sub-config-selected-row-enter.sub-config-selected-row-enter-active {
  @include slide-in-opacity-end-animation(200px, all .5s linear);
}

.sub-config-selected-row-leave {
  @include slide-out-opacity-start-animation(200px);
}

.sub-config-selected-row-leave.sub-config-selected-row-leave-active {
  @include slide-out-opacity-end-animation(all .5s ease-out);
}

// Subscription config - section animation
// @TODO - minor UI bug, figure out what causes the small bounce on the open animation in the quantity section
.sub-config-section-enter {
  @include slide-in-opacity-start-animation();
}

.sub-config-section-enter.sub-config-section-enter-active {
  @include slide-in-opacity-end-animation(500px, all .5s linear);
}

.sub-config-section-leave {
  @include slide-out-opacity-start-animation(500px);
}

.sub-config-section-leave.sub-config-section-leave-active {
  @include slide-out-opacity-end-animation(all .5s ease-out);
}
