/* SVGs */

.lmi {
  background: url('../static/images/svg/GoTo_logo.svg') no-repeat;
  width: 25px;
  height: 25px;
  background-position: -55px 0px;
}

.nav-prodlogo,
.nav-prodlogo-inline-block {
  background: url('../static/images/svg/goto-logos-small.svg') no-repeat;
  width: 18px;
  height: 18px;
  border-radius: unset !important;
  &.gotomeeting {
    background-position: 0px 2px;
  }
  &.gotowebinar {
    background-position: 0px -30px;
  }

  &.gototraining {
    background-position: 0px -61px;
  }

  &.gotoassist {
    background-position: 0px -90px;
  }

  &.seeit {
    background-position: 0px -120px;
  }

  &.gotomypc {
    background-position: 0px -148px;
  }

  &.grasshopper {
    background-position: 0px -180px;
  }

  &.openvoice {
    background-position: 0px -210px;
  }

  &.prompt {
    background: url('../static/images/svg/prompt-logo.svg') no-repeat;
    background-position: 2px 0px;
    background-size: 20px;
  }
  &.jive,
  &.gotoconnect {
    background: url('../static/images/svg/GoToConnect-daisy.svg') no-repeat;
    background-position: 3px 0px;
    background-size: 20px;
  }
  &.hvoice {
    background: url('../static/images/svg/hvoice-logo.svg') no-repeat;
    background-position: 3px 0px;
    background-size: 20px;
  }
  &.bold360 {
    background: url('../static/images/svg/bold360-logo.svg') no-repeat;
    background-position: 3px 0px;
    background-size: 20px;
  }
  &.resolve {
    background: url('../static/images/svg/GoToResolve-daisy.svg') no-repeat;
    background-position: 3px 0px;
    background-size: 20px;
  }
  &.grasshopper {
    background: url('../static/images/svg/Grasshopper-head.svg') no-repeat;
    background-position: 3px 0px;
    background-size: 20px;
  }
}

.nav-prodlogo-inline-block {
  display: inline-block;
  width: 23px;
  &.jive,
  &.gotoconnect,
  &.hvoice,
  &.bold360 {
    background-position: 0 2px;
    background-size: 15px;
  }
}

