.keyValContainer {
    margin-bottom: 16px;
    &:last-child{
        margin-bottom: 0;
    }
}

.passkeyDetailContainer {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.passkeyInfoBlock {
    margin-top: -5px;
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
    align-items: flex-start;
}