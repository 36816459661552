.connectToIconGroup {
    display: flex;
    gap: 8px;
    align-items: center;
}

.connectAsIconGroup {
    display: flex;
    gap: 8px;
    align-items: center;
}

.connectedAsContainer {
    display: flex;
    justify-content: space-between;
}

.connectToSocialBlock {
    margin-bottom: 16px;
}