@import 'src/styles/_variables.scss';
.modalActions, .modalHeader {
   display: flex;
   align-items: center;
   padding: 0;
   height: 49px;
   border-radius: 0;
   padding: 0 16px;
}


.modalActions {
    bottom: 0;
    position: absolute;
    width: calc(100vw - 32px);
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: 0px -4px 20px 0px #00000014;
}

.modalHeader {
    flex-direction: row;
    box-shadow: 0px 4px 20px 0px #00000014;
}

.modalContainer {
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    z-index: 1101;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}

.modalContent {
    padding: 24px 16px;
    flex-grow: 100;
}

.returnIcon {
    cursor: pointer;
}

.dialogChildren {
    border-top: 1px solid #58798F80; 
    padding: 15px 15px 0 15px;
    margin-left: -21px;
    margin-right: -21px;
}

@media (max-width: $screen-xs-max) {
    .flexSideSmall {
        display: none;
    }
  }
  
  @media (min-width: $screen-xs-max ) {
    .flexBottomLarge{
        display: none;
    }
  }