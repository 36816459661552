@import 'src/styles/_variables.scss';

.iconGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 14px;
    padding: 14px;
    align-items: center;
}

.navContainer {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    border-bottom: 1px  solid #58798F80;
    height: 49px;
    border-radius: 0;
}

.menuOptions {
  --trigger-x: 0 !important;
}

.supportLinks{
    display: flex;
}

.navIcon {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.avatar-nav {
    background: none;
    border: none;
    cursor: pointer;
}

.avatarInfo {
    display: flex;
    padding: 6px 10px;
    gap: 7px;
}

.copyRightBlock {
    display: flex;
    justify-content: center;
    height: 24px;   
}
.sideNavToggle {
    display: inline-block;
}

@media (max-width: $screen-xs-max) {
    .sideNavToggle, .avatar-nav, .supportLinks {
        display: none;
    }
}

@media (min-width: $screen-xs-max ) {
    .fluidOptions{
        display: none;
    }
}