@import '../styles/variables';

.alertContainer {
  display: flex;
  justify-content: center;

  .bannerMessageIcon {
    font-size: 24px;
  }

  .secondPartOfText {
    margin-left: 6px;
  }

  .bannerMessageCTA {
    margin-right: 0;
    height: fit-content;
    border: 1px solid rgb(255, 255, 255);
  }
}
.alertBox{
  display: grid;
  padding: 9px;
}

.bannerMessageText {
  display: inline-flex;
}

.closeContainer {
  position: absolute;
  cursor: pointer;
  right: 15px;
  font-size: 16px;
  top: 14px;
}
@media (max-width: $screen-xs-max) {
  .content {
    display: flex;
    gap: 4px;
    margin-right: 20px;
  }
  .icon{
    margin-top: 3px;
  }
}
@media (min-width: $screen-xs-max) {
  .content {
    display: flex;
    gap: 4px;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
  }
  .alertHeading {
    padding-top: 3px;
    i {
      font-size: 1.1em;
      vertical-align: bottom;
    }
  }
  .alertDescription {
    padding-top: 9px;
  }
  .alertButtons {
    float: right;
    margin-right: 5px;
    margin-top: 2px;
  }
}
