@import './variables';

@mixin vertical-line($left-padding, $right-padding) {
  content: "|";
  color: $light-gray;
  padding: 0 $right-padding 0 $left-padding;
}

// ----- Animation mixins -----
// Make visible / hidden animation using opacity
@mixin opacity-visible-start-animation() {
  overflow: hidden;
  opacity: 0.01;
}

@mixin opacity-visible-end-animation($transition) {
  opacity: 1;
  transition: $transition;
}

@mixin opacity-hidden-start-animation() {
  overflow: hidden;
  opacity: 1;
}

@mixin opacity-hidden-end-animation($transition) {
  opacity: 0.01;
  transition: $transition;
}

// Make slide in / out animation with opacity effect (transition opacity and max height)
@mixin slide-in-opacity-start-animation() {
  overflow: hidden;
  opacity: 0.01;
  max-height: 0;
}

@mixin slide-in-opacity-end-animation($max-height, $transition) {
  opacity: 1;
  max-height: $max-height;
  transition: $transition;
}

@mixin slide-out-opacity-start-animation($max-height) {
  overflow: hidden;
  opacity: 1;
  max-height: $max-height;
}

@mixin slide-out-opacity-end-animation($transition) {
  opacity: 0.01;
  max-height: 0;
  transition: $transition;
}

@mixin right-margin-only($margin) {
  margin: 0 $margin 0 0;
}
