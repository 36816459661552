.strongPasswordCollapsibleText {
    padding: 12px 8px;
    height: 100%;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #F7F7F7;
    margin-bottom: 15px;
    font-size: 13px;
    margin-top: -1px;
}

.tipsList {
    margin: 0;
}
.tipsInfoText{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.strength{
    height: 3px;
    background: #b1b1b1;
    width: 24%;
    display: inline-block;
    margin-right: 5px;
    border-radius: 8px;
    margin-bottom: 8px;
}
.strength:last-child {
    margin-right: 0px;
}
.placeHolderText{
    color: rgba(42, 50, 56, 0.7);
    font-weight: 400;
    margin-bottom: 0;
}
.passwordStrengthText{
    margin-bottom: 0px;
}
.passwordStrengthIndicatorDiv{
    height: 16px;
}
.helpText {
    color: #e02d52;
    margin-bottom: 1px;
}