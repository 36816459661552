@import 'src/styles/_variables.scss';

.developerToolsContainer {
  max-width: 620px;
  margin: 0 auto;
}

.spaceBetweenContent {
  display: flex;
  justify-content: space-between;
}

.mainContentDeveloperTools {
  height: 88vh;
  overflow-x: hidden;
}

.controlWidth {
  width: 45%;
  outline: none;
}

.createTokenCta {
  margin-top: 22px;
}

.developerToolSectionTitle {
  display: flex;
  padding-bottom: 10px;
  color: #4a4a4a;
  margin-top: 10px;
  &.midPage {
    padding-top: 25px;
  }
  i {
    font-size: 14px;
    top: -2px;
  }
}


.tokenItem {
  padding: 13px 0;
  border-bottom: 1px solid #cecece;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.individualProgressBlock {
  height: 6px;
  display: flex;
  background-color: #000000;
  width: 50%;
}
@media (max-width: $screen-xs-max) {
  .spaceBetweenContent {
    display: block;
  }

  .createTokenCta {
    margin: 0;
  }

  .controlWidth {
    width: 100%;
    outline: none;
  }
}

.progressBlock {
  display: flex;
  gap: 8px;
  margin: 0 24px;
}

.enableEnhanceSecurity {
  margin-top: 6px !important;
}

.emptyStateStyle {
  position: absolute;
  display: flex;
  top: 8vh;
  bottom: 4vh;
  right: 0;
  left: 0;
}

.createTokenMain {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.alertSection {
  margin-top: 16px;
}

.developerToolSectionLimit {
  padding-bottom: 10px;   
  color: #4a4a4a;
  &.midPage {
    padding-top: 25px;
  }
  i {
    font-size: 14px;
    top: -2px;
  }
}

.headerSection {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}
.mainForm {
  padding-top: 16px;
  max-width: 520px;
  margin: 0 auto 26px auto;
  padding: 20px;

  div {
    margin-top: 16px;
  }
}

.overflowContainer {
  overflow: auto;
}

.doneBtn{
  margin-left: auto;
}

.ctaSection {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: auto;
}

.developerToolsInfo {
  border-color: #58798F80;
  border-style: solid;
  width: 95%;
  border-width: 1px;
  padding: 10px;
  border-radius: 8px;
  background-color: #F7F7F7;
  margin-left: 15px;
  color: #4a4a4a;
  margin-bottom: 10px;
  display: flex;
}

.developerToolsManageInfo {
  border-color: #58798F80;
  border-style: solid;
  width: 95%;
  border-width: 1px;
  border-radius: 8px;
  background-color: #F7F7F7;
  margin-left: 15px;
  color: #4a4a4a;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  padding: 10px;
}

.enhancedInfo {
  border-color: #1B8756;
  border-style: solid;
  width: 95%;
  display: flex;
  border-width: 1px;
  padding: 10px;
  border-radius: 8px;
  background-color: #1B87561A;
  text-align: left;
  margin-left: 15px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.editIcon{
  color:#006aff;
  margin-right: 20px;
}

.yourToken {
  font-size: 16px !important;
  font-family: lato-bold;
  letter-spacing: .5px;
  padding-bottom: 8px;
  color: #4a4a4a;
}

.infoText {
  :nth-of-type(2) {
    margin-top: 16px;
  }
  :last-child {
    margin-bottom: 16px;
  }
}
.pesonalTokenIdSection {
  padding: 12px;
  border: 1px solid $common-border;
  border-radius: 4px;

  .tokenDisplay {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    background-color: rgba(44,114,222,.1);
  }
}

.btn-create-token{
  display: flex;
  flex-wrap: wrap;
  width: 80px;
  padding: 10px;
}

.btn-create{
  width: 30%;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font-family: lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px !important;
  margin-left: 15px;
}

.sectionInfo {
  font-size: 14px;
  line-height: 1.43;
  padding: 10px 0 4px;
  letter-spacing: 0.2px;
  color: #444444;
}

.personAccessTokenInfo {
  border: white;
  padding-left: 1px;
  border-color: lightgrey;
  border-style: solid;
  border-width: 1px;
  margin-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 20px;
  width: 95%;
}


.personAccessTokenIdCopy {
  border: white;
  padding-left: 10px;
  padding: 10px;
  background-color: #D9E9FF;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonRow {
  margin-top: 10px;
  button:first-child {
    margin-right: 24px;
  }
}

.btn-cancel-scopes {
  font-size: 14px;
  padding: 10px 18px;
  border-radius: 4px;
  letter-spacing: 1px;
  line-height: 17px;

  border-width: 2px;
  border-style: solid;
  border-color: #d9e9ff !important;
  background-color: #d9e9ff;
  color: #006aff;
  margin-bottom: 20px;
  margin-top: 10px;
}

.btn-cancel-scopes[disabled] {
  background-color: #f0f2f5;
  border-color: #f0f2f5 !important;
  color: #006aff !important
}

.btn-cancel-scopes:not(:disabled):hover {
  color: #006aff;
  background-color: #f0f2f5;
  border-color: #f0f2f5 !important;
}

.btn-next-scopes {
  font-size: 14px;
  padding: 10px 18px;
  border-radius: 4px;
  letter-spacing: 1px;
  line-height: 17px;

  border-width: 2px;
  border-style: solid;
  background-color: #006aff ;
  border-color: #006aff ;
  color: #FFFFFF ;
  margin-bottom: 20px;
  margin-top: 10px;
}

.btn-next-scopes[disabled] {
  background-color: #bfdaff;
  border-color: #bfdaff;
}

.btn-next-scopes:not(:disabled):hover {
  background-color: #004fbd !important;
  border-color: #004fbd !important;
}

.addTabNav,
.editTabNav {
  margin: 20px 0 !important;
  div {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    & a + div {
      background: transparent;
      border-radius: 7px 7px 0 0;
      height: 4px;
      &.active {
        background: rgb(0, 106, 255);
      }
    }
  }
  a {
    font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #FFFFFF;
    border: none;
    display: inline-block;
    height: 26px;
    padding: 0 12px;
    color: #6b7582 !important;
    &:hover {
      color: $link-hover-color !important;
    }
    &.active {
      color: #25282d !important;
    }
  }
  & .stepIndicator {
    margin-right: 5px;
    font-family: lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #FFFFFF;
    border: none;
    border-bottom: 3px solid #c3c3c3;
    height: 26px;
    padding: 0 12px;
    color: #767676 !important;

    &.active {
      border-bottom: 3px solid $link-color;
      color: #444444 !important;
    }
  }
}

.editTabNav {
  border-bottom: 2px solid #cdd4dd;
}

.thead {
  background-color: lightgrey;
  & .sortCTA
  {
    &:hover{
    cursor: pointer;
    }
  }
}

.addTabNav {
  margin-left: 0;
  margin-right: 0;
  & > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  & .stepIndicator {
    color: #25282D !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px;
    padding-left: 0;
    padding-right: 0;
    border: none;
    .stepProgress {
      & > div {
        padding-top: 6px;
      }
      .stepStart,
      .stepComplete {
        background-color: #dee4ec;
        height: 1px;
        display: inline-block;
        width: 50%;
        margin: auto 0;
        position: relative;
        top: 7px;
      }
      .stepMarker {
        background-color: #dee4ec;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        margin: 0 auto;
        top: -20px;
      }
    }
    &.active,
    &.completed {
      border: none;
      color: #444444 !important;
      .stepMarker {
        background-color: blue;
      }
      .stepStart {
        background-color: blue;
        height: 3px;
        top: 6px;
      }
    }
    &.completed {
      .stepComplete {
        background-color: blue;
        height: 3px;
        top: 6px;
      }
    }
  }
}

.credentialsValue{
  padding-left: 0px;
  font-size: 14px;

  @media screen and (max-width: 600px) {
  font-size: 12px;
  word-wrap: break-word;
  }

  @media screen and (max-width: 300px) {
  font-size: 10px;
  word-wrap: break-word;
  }
  
  color: #4a4a4a;
}
.credentialsCopy {
  font-size: 16px;
  color: #4a4a4a;
}
.credentialsCopy {
  text-align: right;
}

.tokenCopyCTA {
  background: none;
  border: none;
  text-decoration: none;
  color: #006aff;
  margin-left: 10px;
  font-size: 12px;
  font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  &:hover {
    color: $link-hover-color;
  }
  & > span {
    padding-left: 6px;
    display: inline !important;
    position: relative;
    top: -3px;
  }
  & i {
    font-size: 20px;
  }
}

.socialLogo {
  margin-right: 15px;
  margin-bottom: 12px;
}

.disabled{
  opacity: 0.5;
  pointer-events: none;
}

.flexSideButton {
  margin: 0 !important;
}

.copyButtonStyle {
  margin-top: 1px !important;
}

@media (max-width: $screen-xs-max) {
  .flexSideSmall {
      display: none;
  }
}

@media (min-width: $screen-xs-max ) {
  .flexBottomLarge{
      display: none;
  }
}