.notificationBlock {
    margin: 10px;
    position:initial;
    z-index: 500;
    animation-name: toastr;
    animation-duration: 0.2s;
}

@keyframes toastr {
    from {
        position: fixed;
        margin-left: -231px;
    }
    to {
        margin-left: 10px;
    }
}

.notificationInTokenCreation {
    margin: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 500;
    animation-name: toastInToken;
    animation-duration: 0.2s;
}

@keyframes toastInToken {
    from {
        position: fixed;
        left: -200px;
    }
    to {
        left: 0px;
    }
    
}