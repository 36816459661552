@import '../styles/variables';

.deviceActionTrigger {
  font-size: 22px;
  line-height: 17px;
  transform: rotate(90deg);
  cursor: pointer;
}

.deviceListItem {
  border-bottom: 1px solid #cecece;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 16px;
  cursor: pointer;
}

.deviceMenuCell,
.deviceTypeInfo,
.riskIndicatorCol,
.timeAndLocationText
{
  display: inline-block;
}

.deviceTypeInfo {
  width: 90%;
  cursor: pointer;
}

.deviceMenuCell {
  padding-right: 10px;
  text-align: right;
  width: 10%;
  vertical-align: top;
  position: relative;
  > a {
    color: #444;
    width: 36px;
    height: 30px;
    display: inline-block;
    padding-top: 4px;
    vertical-align: middle;
    text-align: right;
    &:hover {
      color: #1678e3;
    }
    &:focus {
      color: #1678e3;
      outline: auto;
    }
  }
}

.deviceMenuDropdown {
  right: 20px;
  top: 10%;
  left: auto;
  padding: 5px 0;
  min-width: 100px;
  > li > a {
    padding: 5px 15px;
    &:hover,
    &:focus {
      background-color: #eef6ff;
      color: #434B58;
    }
  }
}

.devicePlatform {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.2px;
  color: #434B58;
  overflow: auto;
  padding-top: 6px;
  padding-bottom: 7px;
  display: inline-block;
}

.deviceLocation {
  display: flex;
  align-items: center;
}

.deviceLocationSeparator {
  padding: 0 5px;
}

.deviceTypeRow {
  padding: 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deviceInfoRow {
  padding: 0 0 12px;
  cursor: pointer;
}

.deviceNote {
  display: flex;
  align-items: center;
}

.timeAndLocationText {
  position: relative;
  padding-left: 22px;
  line-height: 1.5;
  margin-top: 2px;
}
.riskIndicatorCol {
  position: absolute;
  top: -2px;
  left: 0;
  width: 16px;
  text-align: center;
}
.highRiskDeviceIcon,
.lowRiskDeviceIcon {
  color: #e02d52;
  font-size: 16px;
}
.lowRiskDeviceIcon {
  color: #1678e3;
}

.clickedDevice {
  background-color: #f3f3f3;
}
