@import '../../styles/variables';

// for LabelValueVerticalIntl
.value {
  color: $dark;
  font-weight: 800;
  font-size: 1.2em;

  @media (max-width: $screen-xs-max) {
    font-size: 1em;
  }
}

.label-value-vertical {
  li {
    display: table-cell;
    vertical-align: middle;
  }
}
