@import '../styles/variables';

.permission-item-row {
  padding: 16px;
  border: 1px solid $common-border;
  border-radius: 4px;

  .permission-subtitle {
    display: flex;

    .permission-subtitle-text {
      margin: 0;
    }
  }
  .advanced-scopes-toggle {
    cursor: pointer;
  }
}

.childScope {
 display: flex;
 flex-direction: column;
 gap: 11px;
}

.child-scope-checker {
  display: flex;
  height: auto;
}
    
  