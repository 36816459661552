@import './variables';

.toastifyToast {
  min-height: 48px !important;
  height: auto !important;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px !important;
  padding: 15px 15px 15px 20px !important;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  color: #444;
  background-color: #FFF;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  font-family: lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  &:hover {
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer;
  }
  &.info {
    border-left: 7px solid #46a9f6;
    background-color: #FFF !important;
  }
  &.success {
    border-left: 7px solid #00b984;
    background-color: #FFF !important;
  }
  &.error {
    border-left: 7px solid #f53d5a;
    background-color: #FFF !important;
  }
  button {
    color: #444;
    border: none !important;
    background: none !important;
    background-color: #FFF;
    height: 24px;
  }
}


//.toastifyToast {
//  min-height: 48px !important;
//  pointer-events: auto;
//  overflow: hidden;
//  margin: 0 0 6px;
//  padding: 15px 15px 15px 54px;
//  -moz-border-radius: 3px 3px 3px 3px;
//  -webkit-border-radius: 3px 3px 3px 3px;
//  border-radius: 3px 3px 3px 3px;
//  background-position: 15px center;
//  background-repeat: no-repeat;
//  //-moz-box-shadow: 0 0 12px #999999;
//  //-webkit-box-shadow: 0 0 12px #999999;
//  //box-shadow: 0 0 12px #999999;
//  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
//  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
//  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
//  color: #FFFFFF;
//  opacity: 0.8;
//  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
//  filter: alpha(opacity=80);
//  &:hover {
//    opacity: 1;
//    //-moz-box-shadow: 0 0 12px #000000;
//    //-webkit-box-shadow: 0 0 12px #000000;
//    //box-shadow: 0 0 12px #000000;
//    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//    filter: alpha(opacity=100);
//    cursor: pointer;
//  }
//  &.info {
//    //background-color: #2F96B4 !important;
//    //background-color: #46a9f6 !important;
//    background-color: #2874E0 !important;
//    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
//  }
//  &.success {
//    //background-color: #51A351 !important;
//    //background-color: #00b984 !important;
//    background-color: #00AA6E !important;
//    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
//  }
//  &.error {
//    //background-color: #BD362F !important;
//    //background-color: #f53d5a !important;
//    background-color: #E02D52 !important;
//    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
//  }
//}
